﻿/* global tumarket */

import * as $ from "jquery";

import "bootstrap/js/dist/popover";
import "owl.carousel";
import tumCommon from "../common.js";
import productsList from "../search/productsList.js";
import tu_slider from "../slider.js";
import otherCitiesSearch from "../search/OtherCitiesSearch.js";
import { ajaxSend } from "../global/fetch";
import "readmore-js";
import { showAdultWarning } from "../product/restrictAdult.js";
import { getPrecompiledTemplate, loadPrecompiledTemplates } from "../global/templates";
import { initTuPopover } from "../global/popover";
import previews from "../previews/previewsEmbed.js";
import { addEventDelegate, insertHtmlBefore, prependHtml, toggleClass } from "../global/dom";
import { isMob } from "../global/util";
import { searchWidgets } from "../navbar/searchSelect.js";

let home_news = {
	options: {
		storageName: 'tum-hiddenNews'
	},
	hideItem: function (id) {
		toggleClass('.home-news .slider-slide[data-previewid="' + id + '"]', 'd-none', true);

		let hiddenItems = this.getHiddenItems();
		hiddenItems.push(id);
		this.setHiddenItems(hiddenItems);
	},
	applyHiddenItems: function () {
		let hiddenItems = this.getHiddenItems();
		for (let item of hiddenItems) {
			toggleClass('.home-news .slider-slide[data-previewid="' + item + '"]', 'd-none', true);
		}
	},
	getHiddenItems: function () {
		let _hiddenItems = localStorage.getItem(this.options.storageName);
		let hiddenItems = _hiddenItems ? JSON.parse(_hiddenItems) : [];
		return hiddenItems;
	},
	setHiddenItems: function (hiddenItems) {
		localStorage.setItem(this.options.storageName, JSON.stringify(hiddenItems));
	},
	init: function () {
		addEventDelegate(document, 'click', '.news-hide', element => {
			let id = element.closest('.slider-slide').dataset.previewid;
			this.hideItem(id);
		});
	}
};

var home = {
	options: {
		isMobSearch: false,
		isDotsEach: false,
		isAutoPlay: false,
		autoplayTimeout: 1000,
		showOtherCities: false
	},
	init: function (options) {
		home.options = $.extend(home.options, options);
		this.getSliderItems();
		this.initEvents();

		document.querySelector('.catalog-slider-container').addEventListener('tu.shown', () => {
			$(".catalog-slider-container .owl-carousel").owlCarousel({
				loop: false,
				autoplay: home.options.isAutoPlay,
				autoplayTimeout: home.options.autoplayTimeout,
				autoplayHoverPause: true,
				dotsEach: home.options.isDotsEach,
				mouseDrag: true,
				responsive: {
					0: {
						items: 2
					},
					992: {
						items: 4
					}
				}
			});

			for (let elem of document.querySelectorAll('.owl-dot')){
				elem.setAttribute('aria-label', 'Далее');
			}
		});

		toggleClass(".mob-search-btn", "d-none", false);

		this.applyUrlParams();

		document.querySelector('.home-products-list').addEventListener('tu.shown', () => {
			productsList.init({
				url: '/home/getProducts',
				container: '.home-products-list',
				dataUpdateCallback: (state, data) => {
					if (!document.querySelector('.ctuCloud')) {
						var ctuCloud = data.CtuCloud && JSON.parse(data.CtuCloud);
						this.renderCtuCloud(ctuCloud);
					}
				},
				renderOnInit: true
			})
			.then(() => tumCommon.initCtuCloud());
		});

		document.querySelector('[data-previews]').addEventListener('tu.shown', () => {
			previews.init({
				container: "[data-previews]"
			});
		});

		home_news.init();
	},
	initEvents: function () {
		$(document).on('initialized.owl.carousel', '.owl-carousel', function () {
			$(".owl-carousel").closest('.catalog-slider-container').removeClass('invisible');
		});

		addEventDelegate(document, 'click', ".b-firm-annotation", element => {
			$(element).readmore("toggle");
		});
	},
	applyUrlParams: function () {
		let searchType = new URL(location.href).searchParams.get('searchType') || "byGoods";

		searchWidgets.main.setSearchType(searchType);
	},
	initProductSlider: function (container) {
		window.sliders = window.sliders || [];

		for (let sliderElement of container.querySelectorAll('.tu-slider')) {
			let totalProductIDs = sliderElement.closest(".tum-home-productslider").dataset.ids.split(',');

			let slider = tu_slider.create({
				container: sliderElement,
				getItems: (callback) => {
					let productIDs = totalProductIDs.slice(5);
					loadPrecompiledTemplates(['product-mini-wrapper']).then(() => {
						return ajaxSend({ url: '/search/GetProductMiniatures', data: { 
							productIDs: productIDs, 
							isNeedLinkUnder: true,
							contextType: sliderElement.closest(".tum-home-productslider").dataset.contextType
						 } });
					}).then(data => {
						if (data.result) {
							let template = getPrecompiledTemplate('product-mini-wrapper');
							for (let item of data.Items) {
								item.isSlider = true;
								item.isMob = isMob();
							}

							let newItems = data.Items.map(e => template(e)).join("");

							insertHtmlBefore(sliderElement.querySelector('.slider-list .slider-additional-slide'), newItems);
							callback();
							initTuPopover();
						}
					});
				},
				totalCount: totalProductIDs.length + 1
			});

			window.sliders.push(slider);
		}

		$('.b-firm-annotation', container).readmore({
			moreLink: '<a class="tum-grey readMore no-font-scaling" href="#" rel="nofollow"><small><i class="fas fa-chevron-down"></i> Ещё</small></a>',
			lessLink: '<a class="tum-grey readLess no-font-scaling" href="#" rel="nofollow"></a>',
			speed: 100,
			collapsedHeight: 97
		});

		toggleClass(container.querySelector(".slider-additional-slide .all-catalog").closest("li"), 'mt-4', false);
		prependHtml(container.querySelector(".slider-additional-slide li a"), '<i class="fas fa-external-link-alt"></i> ');
		toggleClass(container, 'bordered', true);
		$("[data-toggle=popover]", container).popover({
			sanitize: false
		});
	},
	_getSliderItems: function () {
		showAdultWarning();
		this.initProductSlider(document.querySelector(".tum-home-productslider1"));
		this.initProductSlider(document.querySelector(".tum-home-productslider2"));
		this.initProductSlider(document.querySelector(".tum-home-productslider3"));
		initTuPopover();

		if (this.options.showOtherCities) {
			home.getOtherCities();
		}
	},
	getSliderItems: function () {
		let templates = ['product-slider'];
		loadPrecompiledTemplates(templates).then(() => this._getSliderItems());
	},
	getOtherCities: function () {
		ajaxSend({ url: "/Home/GetOtherCities" }).then(data => {
			if (data.result) {
				if (data.OtherCities?.length > 0) {
					otherCitiesSearch.init({
						firstCity: data.OtherCities ? data.OtherCities[0] : {},
						otherCities: data.OtherCities || []
					});
				}
			}
		});
	},
	renderCtuCloud: function (data) {
		return loadPrecompiledTemplates(['ctu-cloud'])
			.then(() => {
				data.pageType = tumarket.pageType;
				let template = getPrecompiledTemplate('ctu-cloud');
				document.querySelector('.home-ctu-cloud').innerHTML = template(data);
			});
	}
};

export default home;
